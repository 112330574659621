import CloseIcon from '@mui/icons-material/Close';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { List, ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useStats } from '../hooks/useStats';

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 350px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 24rem;
  padding: 2em;
  justify-content: flex-start;
  @media (prefers-color-scheme: dark) {
    background-color: #121212;
    color: white;
  }
`;

const StatNumber = styled.div`
  font-weight: bold;
  font-size: 20px;
  text-align: center;
`;

const StatText = styled.div`
  text-align: center;
`;

const StyledTile = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const StatsTile = ({ stat, text }: { stat: number; text: string }) => (
  <StyledTile>
    <StatNumber>{stat}</StatNumber>
    <StatText>{text}</StatText>
  </StyledTile>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(3.8rem, 8rem));
  grid-template-rows: auto 1fr;
`;

const StatsButton = styled.button`
  background: none;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  margin-top: 0.6rem;
  padding: 1px 10px;
`;

const StyledModal = styled(Modal)`
  @media (prefers-color-scheme: dark) {
    color: #000;
  }
`;

const DistBar = styled.div<{ count: number; maxDistribution: number }>`
  flex: 0 1
    ${(props) => Math.round((props.count / props.maxDistribution) * 100)}%;
  background-color: #ddd;
  padding: 2px 5px;
  border-radius: 3px;
  margin-left: 0.5rem;
  @media (prefers-color-scheme: dark) {
    color: #000;
  }
`;

const LeaderboardIconStyled = styled(LeaderboardIcon)`
  color: black;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`;

const Type = styled(Typography)`
  font-family: Courier, monospace !important;
  margin-top: 5px !important;
`;

export function StatsModal() {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const { played, winRatio, currentStreak, maxStreak, guessDistribution } =
    useStats();
  const maxDistribution = Math.max(...Object.values(guessDistribution));

  return (
    <div>
      <StatsButton onClick={handleOpen}>
        <LeaderboardIconStyled />
      </StatsButton>
      <StyledModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: 10000 }}
      >
        <StyledBox>
          <Box>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Type id="modal-modal-title" variant="h5">
            Statistics
          </Type>
          <Grid>
            <StatsTile stat={Math.round(winRatio * 100)} text="Win %" />
            <StatsTile stat={played} text="Played" />
            <StatsTile stat={currentStreak} text="Streak" />
            <StatsTile stat={maxStreak} text="Max Streak" />
          </Grid>
          <Type id="modal-modal-title" variant="h6">
            Guess Distribution:
          </Type>
          <List>
            {Object.entries(guessDistribution).map(([index, count]) => (
              <ListItem sx={{ paddingBottom: 0 }} key={index}>
                <div>{index}</div>
                <DistBar count={count} maxDistribution={maxDistribution}>
                  {count}
                </DistBar>
              </ListItem>
            ))}
          </List>
          <Type id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={() => {
                window.open('https://crisisrelief.un.org/t/ukraine');
              }}
            >
              🇺🇦 Donate to Ukraine ❤️
            </Button>
          </Type>
        </StyledBox>
      </StyledModal>
    </div>
  );
}
