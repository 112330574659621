/* prettier-ignore */
const countryData: {
  [key: string]: {
    code: string;
    latitude: number;
    longitude: number;
  };
} = {
  Andorra: {
    code: "ad",
    latitude: 42.546245,
    longitude: 1.601554,
  },
  "United Arab Emirates": {
    code: "ae",
    latitude: 23.424076,
    longitude: 53.847818,
  },
  Afghanistan: {
    code: "af",
    latitude: 33.93911,
    longitude: 67.709953,
  },
  "Antigua and Barbuda": {
    code: "ag",
    latitude: 17.060816,
    longitude: -61.796428,
  },
  Anguilla: {
    code: "ai",
    latitude: 18.220554,
    longitude: -63.068615,
  },
  Albania: {
    code: "al",
    latitude: 41.153332,
    longitude: 20.168331,
  },
  Armenia: {
    code: "am",
    latitude: 40.069099,
    longitude: 45.038189,
  },
  Angola: {
    code: "ao",
    latitude: -11.202692,
    longitude: 17.873887,
  },
  Antarctica: {
    code: "aq",
    latitude: -75.250973,
    longitude: -0.071389,
  },
  Argentina: {
    code: "ar",
    latitude: -38.416097,
    longitude: -63.616672,
  },
  "American Samoa": {
    code: "as",
    latitude: -14.270972,
    longitude: -170.132217,
  },
  Austria: {
    code: "at",
    latitude: 47.516231,
    longitude: 14.550072,
  },
  Australia: {
    code: "au",
    latitude: -25.274398,
    longitude: 133.775136,
  },
  Aruba: {
    code: "aw",
    latitude: 12.52111,
    longitude: -69.968338,
  },
  Azerbaijan: {
    code: "az",
    latitude: 40.143105,
    longitude: 47.576927,
  },
  "Bosnia and Herzegovina": {
    code: "ba",
    latitude: 43.915886,
    longitude: 17.679076,
  },
  Barbados: {
    code: "bb",
    latitude: 13.193887,
    longitude: -59.543198,
  },
  Bangladesh: {
    code: "bd",
    latitude: 23.684994,
    longitude: 90.356331,
  },
  Belgium: {
    code: "be",
    latitude: 50.503887,
    longitude: 4.469936,
  },
  "Burkina Faso": {
    code: "bf",
    latitude: 12.238333,
    longitude: -1.561593,
  },
  Bulgaria: {
    code: "bg",
    latitude: 42.733883,
    longitude: 25.48583,
  },
  Bahrain: {
    code: "bh",
    latitude: 25.930414,
    longitude: 50.637772,
  },
  Burundi: {
    code: "bi",
    latitude: -3.373056,
    longitude: 29.918886,
  },
  Benin: {
    code: "bj",
    latitude: 9.30769,
    longitude: 2.315834,
  },
  Bermuda: {
    code: "bm",
    latitude: 32.321384,
    longitude: -64.75737,
  },
  Brunei: {
    code: "bn",
    latitude: 4.535277,
    longitude: 114.727669,
  },
  Bolivia: {
    code: "bo",
    latitude: -16.290154,
    longitude: -63.588653,
  },
  Brazil: {
    code: "br",
    latitude: -14.235004,
    longitude: -51.92528,
  },
  Bahamas: {
    code: "bs",
    latitude: 25.03428,
    longitude: -77.39628,
  },
  Bhutan: {
    code: "bt",
    latitude: 27.514162,
    longitude: 90.433601,
  },
  "Bouvet Island": {
    code: "bv",
    latitude: -54.423199,
    longitude: 3.413194,
  },
  Botswana: {
    code: "bw",
    latitude: -22.328474,
    longitude: 24.684866,
  },
  Belarus: {
    code: "by",
    latitude: 53.709807,
    longitude: 27.953389,
  },
  Belize: {
    code: "bz",
    latitude: 17.189877,
    longitude: -88.49765,
  },
  Canada: {
    code: "ca",
    latitude: 56.130366,
    longitude: -106.346771,
  },
  "Cocos Islands": {
    code: "cc",
    latitude: -12.164165,
    longitude: 96.870956,
  },
  "Democratic Republic of the Congo": {
    code: "cd",
    latitude: -4.038333,
    longitude: 21.758664,
  },
  "Central African Republic": {
    code: "cf",
    latitude: 6.611111,
    longitude: 20.939444,
  },
  Congo: {
    code: "cg",
    latitude: -0.228021,
    longitude: 15.827659,
  },
  Switzerland: {
    code: "ch",
    latitude: 46.818188,
    longitude: 8.227512,
  },
  "Côte d'Ivoire": {
    code: "ci",
    latitude: 7.539989,
    longitude: -5.54708,
  },
  "Cook Islands": {
    code: "ck",
    latitude: -21.236736,
    longitude: -159.777671,
  },
  Chile: {
    code: "cl",
    latitude: -35.675147,
    longitude: -71.542969,
  },
  Cameroon: {
    code: "cm",
    latitude: 7.369722,
    longitude: 12.354722,
  },
  China: {
    code: "cn",
    latitude: 35.86166,
    longitude: 104.195397,
  },
  Colombia: {
    code: "co",
    latitude: 4.570868,
    longitude: -74.297333,
  },
  "Costa Rica": {
    code: "cr",
    latitude: 9.748917,
    longitude: -83.753428,
  },
  Cuba: {
    code: "cu",
    latitude: 21.521757,
    longitude: -77.781167,
  },
  "Cape Verde": {
    code: "cv",
    latitude: 16.002082,
    longitude: -24.013197,
  },
  "Christmas Island": {
    code: "cx",
    latitude: -10.447525,
    longitude: 105.690449,
  },
  Cyprus: {
    code: "cy",
    latitude: 35.126413,
    longitude: 33.429859,
  },
  Czechia: {
    code: "cz",
    latitude: 49.817492,
    longitude: 15.472962,
  },
  Germany: {
    code: "de",
    latitude: 51.165691,
    longitude: 10.451526,
  },
  Djibouti: {
    code: "dj",
    latitude: 11.825138,
    longitude: 42.590275,
  },
  Denmark: {
    code: "dk",
    latitude: 56.26392,
    longitude: 9.501785,
  },
  Dominica: {
    code: "dm",
    latitude: 15.414999,
    longitude: -61.370976,
  },
  "Dominican Republic": {
    code: "do",
    latitude: 18.735693,
    longitude: -70.162651,
  },
  Algeria: {
    code: "dz",
    latitude: 28.033886,
    longitude: 1.659626,
  },
  Ecuador: {
    code: "ec",
    latitude: -1.831239,
    longitude: -78.183406,
  },
  Estonia: {
    code: "ee",
    latitude: 58.595272,
    longitude: 25.013607,
  },
  Egypt: {
    code: "eg",
    latitude: 26.820553,
    longitude: 30.802498,
  },
  "Western Sahara": {
    code: "eh",
    latitude: 24.215527,
    longitude: -12.885834,
  },
  Eritrea: {
    code: "er",
    latitude: 15.179384,
    longitude: 39.782334,
  },
  Spain: {
    code: "es",
    latitude: 40.463667,
    longitude: -3.74922,
  },
  Ethiopia: {
    code: "et",
    latitude: 9.145,
    longitude: 40.489673,
  },
  Finland: {
    code: "fi",
    latitude: 61.92411,
    longitude: 25.748151,
  },
  Fiji: {
    code: "fj",
    latitude: -16.578193,
    longitude: 179.414413,
  },
  "Falkland Islands (Malvinas)": {
    code: "fk",
    latitude: -51.796253,
    longitude: -59.523613,
  },
  Micronesia: {
    code: "fm",
    latitude: 7.425554,
    longitude: 150.550812,
  },
  "Faroe Islands": {
    code: "fo",
    latitude: 61.892635,
    longitude: -6.911806,
  },
  France: {
    code: "fr",
    latitude: 46.227638,
    longitude: 2.213749,
  },
  Gabon: {
    code: "ga",
    latitude: -0.803689,
    longitude: 11.609444,
  },
  "United Kingdom": {
    code: "gb",
    latitude: 55.378051,
    longitude: -3.435973,
  },
  Grenada: {
    code: "gd",
    latitude: 12.262776,
    longitude: -61.604171,
  },
  Georgia: {
    code: "ge",
    latitude: 42.315407,
    longitude: 43.356892,
  },
  "French Guiana": {
    code: "gf",
    latitude: 3.933889,
    longitude: -53.125782,
  },
  Guernsey: {
    code: "gg",
    latitude: 49.465691,
    longitude: -2.585278,
  },
  Ghana: {
    code: "gh",
    latitude: 7.946527,
    longitude: -1.023194,
  },
  Gibraltar: {
    code: "gi",
    latitude: 36.137741,
    longitude: -5.345374,
  },
  Greenland: {
    code: "gl",
    latitude: 71.706936,
    longitude: -42.604303,
  },
  Gambia: {
    code: "gm",
    latitude: 13.443182,
    longitude: -15.310139,
  },
  Guinea: {
    code: "gn",
    latitude: 9.945587,
    longitude: -9.696645,
  },
  Guadeloupe: {
    code: "gp",
    latitude: 16.995971,
    longitude: -62.067641,
  },
  "Equatorial Guinea": {
    code: "gq",
    latitude: 1.650801,
    longitude: 10.267895,
  },
  Greece: {
    code: "gr",
    latitude: 39.074208,
    longitude: 21.824312,
  },
  "South Georgia and the South Sandwich Islands": {
    code: "gs",
    latitude: -54.429579,
    longitude: -36.587909,
  },
  Guatemala: {
    code: "gt",
    latitude: 15.783471,
    longitude: -90.230759,
  },
  Guam: {
    code: "gu",
    latitude: 13.444304,
    longitude: 144.793731,
  },
  "Guinea-Bissau": {
    code: "gw",
    latitude: 11.803749,
    longitude: -15.180413,
  },
  Guyana: {
    code: "gy",
    latitude: 4.860416,
    longitude: -58.93018,
  },
  "Hong Kong": {
    code: "hk",
    latitude: 22.396428,
    longitude: 114.109497,
  },
  "Heard Island and McDonald Islands": {
    code: "hm",
    latitude: -53.08181,
    longitude: 73.504158,
  },
  Honduras: {
    code: "hn",
    latitude: 15.199999,
    longitude: -86.241905,
  },
  Croatia: {
    code: "hr",
    latitude: 45.1,
    longitude: 15.2,
  },
  Haiti: {
    code: "ht",
    latitude: 18.971187,
    longitude: -72.285215,
  },
  Hungary: {
    code: "hu",
    latitude: 47.162494,
    longitude: 19.503304,
  },
  Indonesia: {
    code: "id",
    latitude: -0.789275,
    longitude: 113.921327,
  },
  Ireland: {
    code: "ie",
    latitude: 53.41291,
    longitude: -8.24389,
  },
  Israel: {
    code: "il",
    latitude: 31.046051,
    longitude: 34.851612,
  },
  "Isle of Man": {
    code: "im",
    latitude: 54.236107,
    longitude: -4.548056,
  },
  India: {
    code: "in",
    latitude: 20.593684,
    longitude: 78.96288,
  },
  "British Indian Ocean Territory": {
    code: "io",
    latitude: -6.343194,
    longitude: 71.876519,
  },
  Iraq: {
    code: "iq",
    latitude: 33.223191,
    longitude: 43.679291,
  },
  Iran: {
    code: "ir",
    latitude: 32.427908,
    longitude: 53.688046,
  },
  Iceland: {
    code: "is",
    latitude: 64.963051,
    longitude: -19.020835,
  },
  Italy: {
    code: "it",
    latitude: 41.87194,
    longitude: 12.56738,
  },
  Jersey: {
    code: "je",
    latitude: 49.214439,
    longitude: -2.13125,
  },
  Jamaica: {
    code: "jm",
    latitude: 18.109581,
    longitude: -77.297508,
  },
  Jordan: {
    code: "jo",
    latitude: 30.585164,
    longitude: 36.238414,
  },
  Japan: {
    code: "jp",
    latitude: 36.204824,
    longitude: 138.252924,
  },
  Kenya: {
    code: "ke",
    latitude: -0.023559,
    longitude: 37.906193,
  },
  Kyrgyzstan: {
    code: "kg",
    latitude: 41.20438,
    longitude: 74.766098,
  },
  Cambodia: {
    code: "kh",
    latitude: 12.565679,
    longitude: 104.990963,
  },
  Kiribati: {
    code: "ki",
    latitude: -3.370417,
    longitude: -168.734039,
  },
  Comoros: {
    code: "km",
    latitude: -11.875001,
    longitude: 43.872219,
  },
  "Saint Kitts and Nevis": {
    code: "kn",
    latitude: 17.357822,
    longitude: -62.782998,
  },
  "North Korea": {
    code: "kp",
    latitude: 40.339852,
    longitude: 127.510093,
  },
  "South Korea": {
    code: "kr",
    latitude: 35.907757,
    longitude: 127.766922,
  },
  Kuwait: {
    code: "kw",
    latitude: 29.31166,
    longitude: 47.481766,
  },
  "Cayman Islands": {
    code: "ky",
    latitude: 19.513469,
    longitude: -80.566956,
  },
  Kazakhstan: {
    code: "kz",
    latitude: 48.019573,
    longitude: 66.923684,
  },
  Laos: {
    code: "la",
    latitude: 19.85627,
    longitude: 102.495496,
  },
  Lebanon: {
    code: "lb",
    latitude: 33.854721,
    longitude: 35.862285,
  },
  "Saint Lucia": {
    code: "lc",
    latitude: 13.909444,
    longitude: -60.978893,
  },
  Liechtenstein: {
    code: "li",
    latitude: 47.166,
    longitude: 9.555373,
  },
  "Sri Lanka": {
    code: "lk",
    latitude: 7.873054,
    longitude: 80.771797,
  },
  Liberia: {
    code: "lr",
    latitude: 6.428055,
    longitude: -9.429499,
  },
  Lesotho: {
    code: "ls",
    latitude: -29.609988,
    longitude: 28.233608,
  },
  Lithuania: {
    code: "lt",
    latitude: 55.169438,
    longitude: 23.881275,
  },
  Luxembourg: {
    code: "lu",
    latitude: 49.815273,
    longitude: 6.129583,
  },
  Latvia: {
    code: "lv",
    latitude: 56.879635,
    longitude: 24.603189,
  },
  Libya: {
    code: "ly",
    latitude: 26.3351,
    longitude: 17.228331,
  },
  Morocco: {
    code: "ma",
    latitude: 31.791702,
    longitude: -7.09262,
  },
  Monaco: {
    code: "mc",
    latitude: 43.750298,
    longitude: 7.412841,
  },
  Moldova: {
    code: "md",
    latitude: 47.411631,
    longitude: 28.369885,
  },
  Montenegro: {
    code: "me",
    latitude: 42.708678,
    longitude: 19.37439,
  },
  Madagascar: {
    code: "mg",
    latitude: -18.766947,
    longitude: 46.869107,
  },
  "Marshall Islands": {
    code: "mh",
    latitude: 7.131474,
    longitude: 171.184478,
  },
  Macedonia: {
    code: "mk",
    latitude: 41.608635,
    longitude: 21.745275,
  },
  Mali: {
    code: "ml",
    latitude: 17.570692,
    longitude: -3.996166,
  },
  Myanmar: {
    code: "mm",
    latitude: 21.913965,
    longitude: 95.956223,
  },
  Mongolia: {
    code: "mn",
    latitude: 46.862496,
    longitude: 103.846656,
  },
  Macau: {
    code: "mo",
    latitude: 22.198745,
    longitude: 113.543873,
  },
  "Northern Mariana Islands": {
    code: "mp",
    latitude: 17.33083,
    longitude: 145.38469,
  },
  Martinique: {
    code: "fr",
    latitude: 14.641528,
    longitude: -61.024174,
  },
  Mauritania: {
    code: "mr",
    latitude: 21.00789,
    longitude: -10.940835,
  },
  Montserrat: {
    code: "ms",
    latitude: 16.742498,
    longitude: -62.187366,
  },
  Malta: {
    code: "mt",
    latitude: 35.937496,
    longitude: 14.375416,
  },
  Mauritius: {
    code: "mu",
    latitude: -20.348404,
    longitude: 57.552152,
  },
  Maldives: {
    code: "mv",
    latitude: 3.202778,
    longitude: 73.22068,
  },
  Malawi: {
    code: "mw",
    latitude: -13.254308,
    longitude: 34.301525,
  },
  Mexico: {
    code: "mx",
    latitude: 23.634501,
    longitude: -102.552784,
  },
  Malaysia: {
    code: "my",
    latitude: 4.040255,
    longitude: 109.091912,
  },
  Mozambique: {
    code: "mz",
    latitude: -18.665695,
    longitude: 35.529562,
  },
  Namibia: {
    code: "na",
    latitude: -22.95764,
    longitude: 18.49041,
  },
  "New Caledonia": {
    code: "nc",
    latitude: -20.904305,
    longitude: 165.618042,
  },
  Niger: {
    code: "ne",
    latitude: 17.607789,
    longitude: 8.081666,
  },
  "Norfolk Island": {
    code: "nf",
    latitude: -29.040835,
    longitude: 167.954712,
  },
  Nigeria: {
    code: "ng",
    latitude: 9.081999,
    longitude: 8.675277,
  },
  Nicaragua: {
    code: "ni",
    latitude: 12.865416,
    longitude: -85.207229,
  },
  Netherlands: {
    code: "nl",
    latitude: 52.132633,
    longitude: 5.291266,
  },
  Norway: {
    code: "no",
    latitude: 60.472024,
    longitude: 8.468946,
  },
  Nepal: {
    code: "np",
    latitude: 28.394857,
    longitude: 84.124008,
  },
  Nauru: {
    code: "nr",
    latitude: -0.522778,
    longitude: 166.931503,
  },
  Niue: {
    code: "nu",
    latitude: -19.054445,
    longitude: -169.867233,
  },
  "New Zealand": {
    code: "nz",
    latitude: -40.900557,
    longitude: 174.885971,
  },
  Oman: {
    code: "om",
    latitude: 21.512583,
    longitude: 55.923255,
  },
  Panama: {
    code: "pa",
    latitude: 8.537981,
    longitude: -80.782127,
  },
  Peru: {
    code: "pe",
    latitude: -9.189967,
    longitude: -75.015152,
  },
  "French Polynesia": {
    code: "pf",
    latitude: -17.679742,
    longitude: -149.406843,
  },
  "Papua New Guinea": {
    code: "pg",
    latitude: -6.314993,
    longitude: 143.95555,
  },
  Philippines: {
    code: "ph",
    latitude: 12.879721,
    longitude: 121.774017,
  },
  Pakistan: {
    code: "pk",
    latitude: 30.375321,
    longitude: 69.345116,
  },
  Poland: {
    code: "pl",
    latitude: 51.919438,
    longitude: 19.145136,
  },
  "Saint Pierre and Miquelon": {
    code: "pm",
    latitude: 46.941936,
    longitude: -56.27111,
  },
  "Pitcairn Islands": {
    code: "pn",
    latitude: -24.703615,
    longitude: -127.439308,
  },
  "Puerto Rico": {
    code: "pr",
    latitude: 18.220833,
    longitude: -66.590149,
  },
  Palestine: {
    code: "ps",
    latitude: 31.952162,
    longitude: 35.233154,
  },
  Portugal: {
    code: "pt",
    latitude: 39.399872,
    longitude: -8.224454,
  },
  Palau: {
    code: "pw",
    latitude: 7.51498,
    longitude: 134.58252,
  },
  Paraguay: {
    code: "py",
    latitude: -23.442503,
    longitude: -58.443832,
  },
  Qatar: {
    code: "qa",
    latitude: 25.354826,
    longitude: 51.183884,
  },
  Réunion: {
    code: "re",
    latitude: -21.115141,
    longitude: 55.536384,
  },
  Romania: {
    code: "ro",
    latitude: 45.943161,
    longitude: 24.96676,
  },
  Serbia: {
    code: "rs",
    latitude: 44.016521,
    longitude: 21.005859,
  },
  Russia: {
    code: "ru",
    latitude: 61.52401,
    longitude: 105.318756,
  },
  Rwanda: {
    code: "rw",
    latitude: -1.940278,
    longitude: 29.873888,
  },
  "Saudi Arabia": {
    code: "sa",
    latitude: 23.885942,
    longitude: 45.079162,
  },
  "Solomon Islands": {
    code: "sb",
    latitude: -9.64571,
    longitude: 160.156194,
  },
  Seychelles: {
    code: "sc",
    latitude: -4.679574,
    longitude: 55.491977,
  },
  Sudan: {
    code: "sd",
    latitude: 15.8905231,
    longitude: 30.7671933,
  },
  "South Sudan": {
    code: "ss",
    latitude: 6.85065,
    longitude: 30.659016,
  },
  Sweden: {
    code: "se",
    latitude: 60.128161,
    longitude: 18.643501,
  },
  Singapore: {
    code: "sg",
    latitude: 1.352083,
    longitude: 103.819836,
  },
  "Saint Helena": {
    code: "sh",
    latitude: -24.143474,
    longitude: -10.030696,
  },
  Slovenia: {
    code: "si",
    latitude: 46.151241,
    longitude: 14.995463,
  },
  "Svalbard and Jan Mayen": {
    code: "sj",
    latitude: 77.553604,
    longitude: 23.670272,
  },
  Slovakia: {
    code: "sk",
    latitude: 48.669026,
    longitude: 19.699024,
  },
  "Sierra Leone": {
    code: "sl",
    latitude: 8.460555,
    longitude: -11.779889,
  },
  "San Marino": {
    code: "sm",
    latitude: 43.94236,
    longitude: 12.457777,
  },
  Senegal: {
    code: "sn",
    latitude: 14.497401,
    longitude: -14.452362,
  },
  Somalia: {
    code: "so",
    latitude: 5.152149,
    longitude: 46.199616,
  },
  Suriname: {
    code: "sr",
    latitude: 3.919305,
    longitude: -56.027783,
  },
  "São Tomé and Príncipe": {
    code: "st",
    latitude: 0.18636,
    longitude: 6.613081,
  },
  "El Salvador": {
    code: "sv",
    latitude: 13.794185,
    longitude: -88.89653,
  },
  Syria: {
    code: "sy",
    latitude: 34.802075,
    longitude: 38.996815,
  },
  Eswatini: {
    code: "sz",
    latitude: -26.522503,
    longitude: 31.465866,
  },
  "Turks and Caicos Islands": {
    code: "tc",
    latitude: 21.694025,
    longitude: -71.797928,
  },
  Chad: {
    code: "td",
    latitude: 15.454166,
    longitude: 18.732207,
  },
  "French Southern Territories": {
    code: "tf",
    latitude: -49.280366,
    longitude: 69.348557,
  },
  Togo: {
    code: "tg",
    latitude: 8.619543,
    longitude: 0.824782,
  },
  Thailand: {
    code: "th",
    latitude: 15.870032,
    longitude: 100.992541,
  },
  Tajikistan: {
    code: "tj",
    latitude: 38.861034,
    longitude: 71.276093,
  },
  Tokelau: {
    code: "tk",
    latitude: -8.967363,
    longitude: -171.855881,
  },
  "Timor-Leste": {
    code: "tl",
    latitude: -8.874217,
    longitude: 125.727539,
  },
  Turkmenistan: {
    code: "tm",
    latitude: 38.969719,
    longitude: 59.556278,
  },
  Tunisia: {
    code: "tn",
    latitude: 33.886917,
    longitude: 9.537499,
  },
  Tonga: {
    code: "to",
    latitude: -21.178986,
    longitude: -175.198242,
  },
  Turkey: {
    code: "tr",
    latitude: 38.963745,
    longitude: 35.243322,
  },
  "Trinidad and Tobago": {
    code: "tt",
    latitude: 10.691803,
    longitude: -61.222503,
  },
  Tuvalu: {
    code: "tv",
    latitude: -7.109535,
    longitude: 177.64933,
  },
  Taiwan: {
    code: "tw",
    latitude: 23.69781,
    longitude: 120.960515,
  },
  Tanzania: {
    code: "tz",
    latitude: -6.369028,
    longitude: 34.888822,
  },
  Ukraine: {
    code: "ua",
    latitude: 48.379433,
    longitude: 31.16558,
  },
  Uganda: {
    code: "ug",
    latitude: 1.373333,
    longitude: 32.290275,
  },
  "United States": {
    code: "us",
    latitude: 37.09024,
    longitude: -95.712891,
  },
  Uruguay: {
    code: "uy",
    latitude: -32.522779,
    longitude: -55.765835,
  },
  Uzbekistan: {
    code: "uz",
    latitude: 41.377491,
    longitude: 64.585262,
  },
  "Vatican City": {
    code: "va",
    latitude: 41.902916,
    longitude: 12.453389,
  },
  "Saint Vincent and the Grenadines": {
    code: "vc",
    latitude: 12.984305,
    longitude: -61.287228,
  },
  Venezuela: {
    code: "ve",
    latitude: 6.42375,
    longitude: -66.58973,
  },
  "British Virgin Islands": {
    code: "vg",
    latitude: 18.420695,
    longitude: -64.639968,
  },
  "U.S. Virgin Islands": {
    code: "vi",
    latitude: 18.335765,
    longitude: -64.896335,
  },
  Vietnam: {
    code: "vn",
    latitude: 14.058324,
    longitude: 108.277199,
  },
  Vanuatu: {
    code: "vu",
    latitude: -15.376706,
    longitude: 166.959158,
  },
  "Wallis and Futuna": {
    code: "wf",
    latitude: -13.768752,
    longitude: -177.156097,
  },
  Samoa: {
    code: "ws",
    latitude: -13.759029,
    longitude: -172.104629,
  },
  Kosovo: {
    code: "xk",
    latitude: 42.602636,
    longitude: 20.902977,
  },
  Yemen: {
    code: "ye",
    latitude: 15.552727,
    longitude: 48.516388,
  },
  Mayotte: {
    code: "yt",
    latitude: -12.8275,
    longitude: 45.166244,
  },
  "South Africa": {
    code: "za",
    latitude: -30.559482,
    longitude: 22.937506,
  },
  Zambia: {
    code: "zm",
    latitude: -13.133897,
    longitude: 27.849332,
  },
  Zimbabwe: {
    code: "zw",
    latitude: -19.015438,
    longitude: 29.154857,
  },
};

export default countryData;
